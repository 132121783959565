export const convertToEnglish = str => {
  const hangulToEnglish = {
    ㄱ: 'r',
    ㄲ: 'R',
    ㄴ: 's',
    ㄷ: 'e',
    ㄸ: 'E',
    ㄹ: 'f',
    ㅁ: 'a',
    ㅂ: 'q',
    ㅃ: 'Q',
    ㅅ: 't',
    ㅆ: 'T',
    ㅇ: 'd',
    ㅈ: 'w',
    ㅉ: 'W',
    ㅊ: 'c',
    ㅋ: 'z',
    ㅌ: 'x',
    ㅍ: 'v',
    ㅎ: 'g',
    ㅏ: 'k',
    ㅐ: 'o',
    ㅑ: 'i',
    ㅒ: 'O',
    ㅓ: 'j',
    ㅔ: 'p',
    ㅕ: 'u',
    ㅖ: 'P',
    ㅗ: 'h',
    ㅘ: 'hk',
    ㅙ: 'ho',
    ㅚ: 'hl',
    ㅛ: 'y',
    ㅜ: 'n',
    ㅝ: 'nj',
    ㅞ: 'np',
    ㅟ: 'nl',
    ㅠ: 'b',
    ㅡ: 'm',
    ㅢ: 'ml',
    ㅣ: 'l',
    ㄳ: 'rt',
    ㄵ: 'sw',
    ㄶ: 'sg',
    ㄺ: 'fr',
    ㄻ: 'fa',
    ㄼ: 'fq',
    ㄽ: 'ft',
    ㄾ: 'fx',
    ㄿ: 'fv',
    ㅀ: 'fg',
    ㅄ: 'qt',
  };

  return str
    .split('')
    .map(char => hangulToEnglish[char] || char)
    .join('');
};
